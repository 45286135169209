import { Platform } from "./types";

/**
 * A platform is a representation of any of the three verticals
 * which the Daily Take serves. This is used to filter content
 * based on the platform it is intended for.
 */
export class PlatformClass {
  public static Defense = Platform.Defense;
  public static FedCiv = Platform.FedCiv;
  public static Health = Platform.Health;

  /**
   * Converts a string to a platform. This is useful when
   * converting a platform from a URL parameter or other
   * string representation.
   * @param value The string to convert to a platform.
   * @returns The platform represented by the string.
   */
  public static toPlatform(value: string, defaultValue: string | null = null): Platform | string | null {
    switch (value) {
      case "defense":
      case "Defense":
      case "DEFENSE":
        return Platform.Defense;
      case "fedciv":
      case "FedCiv":
      case "FEDCIV":
        return Platform.FedCiv;
      case "health":
      case "Health":
      case "HEALTH":
        return Platform.Health;
      default:
        return defaultValue;
    }
  }
}
